import React from 'react';
import comingSoonImage from '../assets/LOGO.png'; 
// import comingImage from '../assets/coming.jpeg'; 

const Home = () => {
  return (
    <div style={styles.container}>
      {/* Blurred background image */}
      <div style={styles.background}></div>
      
      {/* Content */}
      <div style={styles.content}>
        <h1 style={styles.heading}>Coming Soon</h1>
        <p style={styles.subheading}>We are working hard to bring something exciting. Stay tuned!</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden', 

  },
  // Background image with blur applied
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${comingSoonImage})`,
    // backgroundImage: `url(${comingImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    filter: 'blur(40px)', // Apply blur effect only to the background image
    zIndex: 0, // Place it behind the content
  },
  content: {
    zIndex: 1, // Ensure the content is above the blurred background
    textAlign: 'center',
    color: '#fff',
    padding: '20px',
  },
  heading: {
    fontSize: '4rem',
    marginBottom: '20px',
    animation: 'fadeIn 2s ease-in-out', // Simple fade-in animation
  },
  subheading: {
    fontSize: '1.5rem',
    fontWeight: '300',
    animation: 'fadeIn 3s ease-in-out', // Subtle fade-in delay
  },
};

export default Home;
