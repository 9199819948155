import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <p style={styles.footerText}>©2024 FoodSick Website. All rights reserved.</p>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#89AAE5',
    color: 'white',
    textAlign: 'center',
    padding: '1rem',
    position: 'absolute',
    bottom: '0',
    width: '100%',
  },
  footerText: {
    margin: 0,
  },
};

export default Footer;